.container {
  display: flex;
  justify-content: center;
}

.card {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 0.75rem;
  background-color: #1f1f1c;
  border-radius: 8px;
  margin: 0.575rem auto;
  width: 100%;
  max-width: 60vw;
  overflow-x: auto;
  border: 1.5px solid rgb(159, 193, 49, 0.5);
  box-sizing: border-box;
}

.colorBox {
  color: white;
  flex: 1 1 auto;
  width: 6rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  font-size: clamp(10px, 1.4vw, 12px);
  font-family: 'Chakra Petch', sans-serif;
  background-color: #444;
  box-sizing: border-box;
}

.colorBox img {
  width: 100%;
  max-width: 3rem;
  height: auto;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.colorBox p {
  margin-top: 0.5rem;
  color: white;
}

.gameItem {
  display: block;
  width: 100%;
  padding: 0.4vh 0;
  border-bottom: 1px solid #ccc;
  text-align: center;
  box-sizing: border-box;
}

.gameItem:last-child {
  border-bottom: none;
}

@media (min-width: 1024px) {
  .card {
    max-width: 50vw;
  }
}

@media (max-width: 768px) {
  .card {
    gap: 0.5rem;
    justify-content: flex-start;
    max-width: 90vw;
    overflow-x: auto;
  }

  .colorBox {
    min-width: 5rem;
    max-width: 7rem;
    font-size: clamp(8px, 1.2vw, 12px);
  }

  .colorBox img {
    max-width: 2.5rem;
  }
}

@media (max-width: 480px) {
  .colorBox {
    min-width: 4rem;
    max-width: 6rem;
    font-size: clamp(8px, 1.2vw, 10px);
  }

  .card {
    max-width: 100vw;
  }
}
