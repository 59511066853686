.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selectorContainer {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90vw;
    position: relative;
    font-family: 'Chakra Petch', sans-serif;
}

.homeButton {
    background-color: #9FC131;
    border: none;
    color: #1f1f1c;
    height: 5vh;
    width: 5vh;
    padding: 0.575rem;
    border-radius: 8px;
    cursor: pointer;
    font-size: clamp(12px, 1.4vw, 16px);
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 0 #738D22;
    transition: all 0.25s ease;
    position: absolute;
    left: 0;
}
  
.homeButton:hover {
    transform: translateY(6px);
    box-shadow: none;
}

.selectContainer {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  
.option {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    cursor: pointer;
    background-color: #1c1c1c;
    border-radius: 5px;
    transition: background-color 0.2s ease;
}
  
.optionImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #555;
}
  
.optionLabel {
    font-size: 14px;
    text-align: left;
    flex-grow: 1;
    color: #fff;
}
  
.optionFocused {
    background-color: #333;
}
  
.selectContainer .option:hover {
    background-color: #555;
}
  
.selectContainer .optionFocused {
    background-color: #333;
}
  

.tippy-box[data-theme~="custom"] {
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    padding: 4px 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
}

.tippy-box[data-theme~="custom"] .tippy-content {
    font-size: 0.8rem;
    text-align: center;
}

.modeButton {
    color: #9FC131;
    padding: 0.575rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.15s ease;
    position: absolute;
    right: 0;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
}

.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card {
    background-color: #1f1f1c;
    border: 1.5px solid #9FC131;
    color: white;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 3rem;
    text-align: center;
    font-family: 'Chakra Petch', sans-serif;
}

.resultContainer {
    margin-top: 15px;
}

.button-container {
    padding: 1rem;
}
  
.button-container button {
    background-color: #9FC131;
    border: none;
    color: #1f1f1c;
    padding: 10px 15px;
    height: 6vh;
    width: 25vw;
    cursor: pointer;
    border-radius: 15px;
    font-size: clamp(12px, 1.4vw, 16px);
    font-weight: bold;
    box-shadow: 0 8px 0 #738D22;
    transition: all 0.25s ease;
    font-family: 'Chakra Petch', sans-serif;
}

.button-container button:hover {
    transform: translateY(6px);
    box-shadow: none;
}

.replay {
    background-color: #9FC131;
    border: none;
    color: #1f1f1c;
    width: 100%;
    max-width: 25vw;
    padding: 1rem 1rem;
    margin-bottom: 3rem;
    border-radius: 15px;
    cursor: pointer;
    font-size: clamp(12px, 1.4vw, 16px);
    font-weight: bold;
    box-shadow: 0 8px 0 #738D22;
    transition: all 0.25s ease;
    font-family: 'Chakra Petch', sans-serif;
}

.replay:hover {
    transform: translateY(6px);
    box-shadow: none;
}

