.container {
    display: flex;
    justify-content: center;
}

.card {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 0.75rem;
    background-color: #1f1f1c;
    border-radius: 8px;
    margin: 0.575rem auto;
    width: 100%;
    max-width: 60vw;
    overflow-x: auto;
    border: 1.5px solid rgb(159, 193, 49, 0.5);
    box-sizing: border-box;
}

.colorBox {
    color: white;
    flex: 1 1 auto;
    width: 6rem;
    height: 8rem;
    height: 3.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    position: relative;
    font-size: clamp(8px, 1.4vw, 10px);
    font-family: 'Chakra Petch', sans-serif;
}

.attributeName {
    padding: 1vw;
    text-align: center;
}

.infoIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Tooltip custom style */
.tippy-box[data-theme~="custom"] {
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    padding: 4px 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
}

.tippy-box[data-theme~="custom"] .tippy-content {
    font-size: 0.8rem;
    text-align: center;
}

@media (min-width: 1024px) {
    .card {
        max-width: 50vw;
    }
}

@media (max-width: 768px) {
    .card {
        gap: 0.5rem;
        justify-content: flex-start;
        max-width: 90vw;
        overflow-x: auto;
    }

    .colorBox {
        min-width: 5rem;
        max-width: 10rem;
        font-size: clamp(8px, 1.2vw, 10px);
    }

    .colorBox img {
        max-width: 2.5rem;
    }
}

@media (max-width: 480px) {
    .colorBox {
        min-width: 4rem;
        max-width: 8rem;
        font-size: clamp(8px, 1vw, 10px);
    }

    .card {
        max-width: 100vw;
    }
}
