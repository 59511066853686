.card {
  background-color: #1f1f1c;
  border: 0.5px solid #9FC131;
  padding: 0.75rem;
  border-radius: 8px;
  width: 100%;
  max-width: 40rem;
  margin: 1rem auto;
  position: relative;
  margin-bottom: 3rem;
  box-sizing: border-box;
}

.temporaryMessage {
  color: #D42D2D;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
  opacity: 0.9;
}

.closedCard {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hintTextClosed {
  font-family: 'Chakra Petch', sans-serif;
  color: #ffffff;
  font-weight: bold;
  font-size: clamp(8px, 1.4vw, 16px);
  position: relative;
}

.toggleButton {
  background-color: transparent;
  color: #9FC131;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
  width: 40px;
  height: 40px;
  font-weight: bold;
  text-align: center;
  z-index: 10;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1rem;
  z-index: 1;
}

.tabButton {
  background-color: #9FC131;
  border: none;
  color: #1f1f1c;
  padding: 10px 15px;
  height: 6vh;
  width: 25vw;
  cursor: pointer;
  border-radius: 15px;
  font-size: clamp(12px, 1.4vw, 16px);
  font-weight: bold;
  box-shadow: 0 8px 0 #738D22;
  transition: all 0.25s ease;
  font-family: 'Chakra Petch', sans-serif;
}

.tabButton:hover {
  transform: translateY(6px);
  box-shadow: none;
}

.locked {
  background-color: #555555;
  cursor: not-allowed;
}

.hintText {
  font-family: 'Chakra Petch', sans-serif;
  color: #ffffff;
  font-weight: bold;
  margin: 2rem 0 0.5rem 0;
}

.hintContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
}

.hintLetter {
  font-size: 2rem;
  color: #9FC131;
  font-family: 'Chakra Petch', sans-serif;
  font-weight: bold;
  margin-top: 0;
}

.audioPlayer {
  width: 100%;
  max-width: 300px;
  margin-top: 1rem;
  outline: none;
  border-radius: 10px;
  background-color: #1f1f1c;
  color: #9FC131;
}

@media (min-width: 1024px) {
  .card {
    max-width: 50vw;
  }
}

@media (max-width: 768px) {
  .buttonContainer {
    flex-wrap: wrap;
  }

  .tabButton {
    width: 100%;
    font-size: clamp(10px, 1.2vw, 14px);
    height: 5vh;
  }

  .card {
    max-width: 90vw;
  }

  .audioPlayer {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .tabButton {
    width: 100%;
    font-size: clamp(8px, 1vw, 12px);
  }

  .card {
    max-width: 100vw;
  }

  .audioPlayer {
    max-width: 80%;
  }
}
