.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  height: 13vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin-bottom: 3rem;
  margin-top: 1rem;
  top: 0;
  z-index: 1000;
  color: white;
}

.App-logo {
  position: absolute;
  height: 25vh;
  width: 25vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  flex-shrink: 0;
  text-align: center;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1c;
  bottom: 0;
  width: 100vw;
  border-top: 1.5px solid rgb(159, 193, 49, 0.5);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  font-size: 0.675rem;
  color: white;
  font-family: 'Chakra Petch', sans-serif;
}

.footer-link {
  font-weight: bold;
  color: #9FC131;
  text-decoration: none;
  margin-left: 5px;
}

.footer-link:hover {
  text-decoration: underline;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}

.quadrillage {
  background-color: #0b1c0a;
  background-image:
    radial-gradient(circle, rgb(159, 193, 49, 0.15) 1px, transparent 1px),
    radial-gradient(circle, rgb(159, 193, 49, 0.15) 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

